import GalleryContent from "./components/galleryContent";
import Header from "../../../components/Common/Header";
import Footer from "../../../components/Common/Footer";
import Menu from "../../../components/Common/Menu";
import type { HeadFC } from "gatsby";
import React from "react";

const IndexPage = () => {
  return (
    <main>
      <Header />
      <Menu />
      <GalleryContent />
      <Footer />
    </main>
  )
}

export default IndexPage;

export const Head: HeadFC = () => <title>Fotogalerias archivos - LaBotana : Noticias y chismes de los famosos, desde 1999</title>
